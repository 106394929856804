import { $, on, removeClass } from 'dom7';
import Cookies from 'js-cookie';
import { scrollToTop } from 'reservation-frontend-commons';
import { AU_ID_AUTH_TICKET_COOKIE_NAME } from '../utils/constants';

$.fn.on = on;
$.fn.removeClass = removeClass;

$(document).on(
  'DOMContentLoaded',
  () => {
    // スクロールトップボタンの設定
    scrollToTop({});

    // auID認可チケットが存在している場合はログアウトボタンを表示
    if (Cookies.get(AU_ID_AUTH_TICKET_COOKIE_NAME)) {
      $('.js-logoutButton').removeClass('is-hide');
    }
  },
  false
);
